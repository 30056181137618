<template>
    <v-row no-gutters>
        <v-col cols="12">
            <!-- hierarchical navigation -->
            <v-row justify="start" class="mt-2 mx-4">
                <v-col cols="12" sm="10" md="8" lg="6" xl="4" class="pa-0">
                <p class="text-caption text-start">
                    <router-link :to="{ alias: 'dashboard' }">Dashboard</router-link> &gt;
                    <router-link :to="{ alias: 'user-account-list' }">Accounts</router-link> &gt;
                    <router-link :to="{ alias: 'account-dashboard', params: { accountId: this.$route.params.accountId } }">{{ accountName }}</router-link>
                </p>
                </v-col>
            </v-row>
            <v-row justify="center" class="py-5 mt-2 px-10">
                <v-col cols="12" sm="10" md="8" lg="6" xl="4" class="pa-0">
                <h1 class="text-h6 font-weight-light text-center">Create new tag</h1>
                <!-- <p class="text-caption text-left mt-8">This will create an independent form for storing your data.</p> -->
                </v-col>
            </v-row>
            <v-row justify="center" class="py-5 px-10">
                <v-col cols="12" sm="10" md="8" lg="6" xl="4" class="pa-0">
                    <v-card tile elevation="4" class="pa-0">
                        <v-toolbar short flat color="white">
                            <v-toolbar-title class="green--text">Create new tag</v-toolbar-title>
                        </v-toolbar>
                        <v-card-text class="px-5">
                        <v-form @submit="create" onSubmit="return false;" @keyup.enter.native="create">
                            <p>
                                A tag is applied to data records so it's easier to find and use them later.
                                You can change the "label" at any time, and all records to which the tag
                                was already applied will be automatically updated.
                                You can also change the "comment" at any time.
                                However, you cannot change the tag type after it's created. If you need
                                a tag for a different data type, create a new tag.
                            </p>

                            <v-select outlined dense :items="newTagTypeChoices" v-model="newTagType" color="teal darken-2" label="Type" required hint="The kind of data to which this tag will be applied">
                            </v-select>

                            <v-text-field
                                ref="tagLabelInput"
                                v-model="newTagLabel"
                                label="Label"
                                :rules="newTagLabelRules"
                                validate-on-blur
                                color="teal darken-2"
                                required
                                type="text"
                                outlined
                                dense
                            >
                            </v-text-field>

                            <v-text-field
                                ref="tagCommentInput"
                                v-model="newTagComment"
                                label="Comment"
                                hint="This is just for you to remember how you are using the tag"
                                :rules="newTagCommentRules"
                                validate-on-blur
                                color="teal darken-2"
                                type="text"
                                outlined
                                dense
                            >
                            </v-text-field>

                        </v-form>
                        </v-card-text>
                        <v-card-actions>
                            <v-spacer></v-spacer>
                            <v-btn elevation="4" class="green white--text" @click="create" :disabled="!isFormComplete">
                                <span>Create</span>
                            </v-btn>
                            <v-btn text color="grey" @click="cancel">
                                <span>Cancel</span>
                            </v-btn>
                            <v-spacer></v-spacer>
                        </v-card-actions>
                    </v-card>
                </v-col>
            </v-row>
        </v-col>
    </v-row>
</template>

<style>
.v-input .v-input__prepend-outer {
    margin-left: 4px !important;
    padding-left: 4px !important;
}

.v-input .v-input__append-outer {
    margin-top: 0px !important;
    padding-top: 0px !important;
}
</style>

<script>
import { mapState } from 'vuex';

export default {
    data: () => ({
        typeList: [],

        error: null,
        website: null,
        // create form
        createTagForm: null,
        // newFormAlias: null,
        // newFormAliasRules: [
        //     (v) => !!v || 'Display alias is required',
        //     (v) => !v || isValidFormAlias(compact(v)) || 'Form alias is required',
        // ],
        newTagLabel: null,
        newTagLabelRules: [
            (v) => !!v || 'Label is required',
        ],
        newTagType: null,
        newTagTypeChoices: [], // will be loaded from server
        newTagComment: null,
        newTagCommentRules: [
            // (v) => !!v || 'comment is required',
        ],
    }),
    computed: {
        ...mapState({
            session: (state) => state.session,
        }),
        isFormComplete() {
            return typeof this.newTagLabel === 'string' && this.newTagLabel.trim().length > 0 && typeof this.newTagType === 'string' && this.newTagType.trim().length > 0;
        },
        accountName() {
            return this.account?.name ?? 'Unknown';
        },
    },
    methods: {
        async loadAccount() {
            try {
                this.error = false;
                this.$store.commit('loading', { loadAccount: true });
                const response = await this.$client.account(this.$route.params.accountId).currentAccount.get();
                console.log(`account/dashboard.vue: response ${JSON.stringify(response)}`);
                if (response) {
                    this.account = response;
                } else {
                    // TODO: redirect back to account list? show a not found message?
                }
            } catch (err) {
                console.error('failed to load account', err);
                this.error = true;
            } finally {
                this.$store.commit('loading', { loadAccount: false });
            }
        },
        async loadTypeList() {
            this.typeList = [{ key: 'email_contact', label: 'Email contact' }];
            this.newTagTypeChoices = this.typeList.map((item) => ({
                text: item.label,
                value: item.key,
            }));
            // TODO: load tag types from server
            /*
            try {
                this.error = false;
                this.$store.commit('loading', { loadTypeList: true });
                const response = await this.$client.account(this.$route.params.accountId).type.search({ status: 'verified' });
                console.log(`account/dashboard.vue: response ${JSON.stringify(response)}`);
                if (response && Array.isArray(response.list)) {
                    this.typeList = response.list;
                    this.newTagTypeChoices = response.list.map((item) => ({
                        text: item.name, // TODO: if type record shows the type has DMARC/DKIM/SPF already set up, maybe we show a checkmark or some other icon next to the name on the list?
                        value: item.name,
                    }));
                } else {
                    // TODO: redirect back to account list? show a not found message?
                }
            } catch (err) {
                console.error('failed to load type list', err);
                this.error = true;
            } finally {
                this.$store.commit('loading', { loadTypeList: false });
            }
            */
        },
        // async loadWebsite(id) {
        //     try {
        //         this.$store.commit('loading', { loadWebsite: true });
        //         const response = await this.$client.account(this.$route.params.accountId).website.get(id);
        //         console.log(`loadWebsite: response ${JSON.stringify(response)}`);
        //         if (response) {
        //             this.website = response;
        //             // if the form is for a website, suggest an appropriate form label (user can still edit this)
        //             if (this.newTagLabel === null || this.newTagLabel.length === 0) {
        //                 this.newTagLabel = `Website content for ${this.website.label}`;
        //             }
        //         }
        //     } catch (err) {
        //         console.error('failed to load website', err);
        //         this.website = null;
        //     } finally {
        //         this.$store.commit('loading', { loadWebsite: false });
        //     }
        // },
        create() {
            if (!this.isFormComplete) {
                return;
            }
            this.createTag();
        },
        cancel() {
            // this.$emit('cancel');
            this.$router.go(-1);
        },
        async createTag() {
            try {
                this.error = false;
                this.$store.commit('loading', { createTag: true });
                console.log('createTag');
                const request = {
                    label: this.newTagLabel,
                    type: this.newTagType,
                    comment: this.newTagComment,
                };
                const response = await this.$client.account(this.$route.params.accountId).tag.create(request);
                console.log('createTag response: %o', response);
                const { isCreated, id, error } = response;
                if (isCreated && id) {
                    // const form = { id, ...request };
                    // this.list.push(form); // this SHOULD work (https://vuejs.org/v2/guide/list.html#Mutation-Methods) but doesn't work.
                    this.$router.replace({ name: 'account-edit-tag', params: { accountId: this.$route.params.accountId, tagId: id } });
                } else if (error) {
                    this.$bus.$emit('snackbar', { type: 'error', headline: 'Failed to create tag' });
                    this.error = error;
                } else {
                    this.$bus.$emit('snackbar', { type: 'error', headline: 'Failed to create tag' });
                    this.error = 'Request failed';
                }
                // this.$emit('created', { id });
            } catch (err) {
                console.error('failed to create tag', err);
                this.$bus.$emit('snackbar', { type: 'error', headline: 'Failed to create tag' });
            } finally {
                this.$store.commit('loading', { createTag: false });
            }
        },
    },
    mounted() {
        this.loadAccount();
        this.loadTypeList();
    },
};
</script>
